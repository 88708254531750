import { modalGeneric } from "./common";

export function dragAndDropUpload() {
    app.DOM.content.find('.upload__').each( function(){
        const $form		 = $( this );
        const $input		 = $form.find( 'input[type="file"]' )
        const $label		 = $form.find( 'label' );
        let droppedFiles = false;
        // const  $errorMsg	 = $form.find( '.upload__error span' );
        // const $restart	 = $form.find( '.upload__restart' );

        const showFiles	 = function( files ){
                if(!files || files.length === 0) {
                    $form.find('.clear-upload').hide();
                    $label.removeClass('blue').removeClass('underline');
                    $label.html('<strong>Choose a file</strong><span class="upload__dragndrop"> or drag it here</span>.');
                    $form.parent().find('.upload__file__list span').html('');
                    return;
                }

                $label.text( files.length > 1 ? ( $label.attr( 'data-multiple-caption' ) || '' ).replace( '[count]', files.length ) : files[0].name );
                $label.addClass('blue').addClass('underline');
                if(files.length > 1) {
                    let fileList = ``;
                    for(let i = 0; i <files.length; i++) {
                        if(!files[i].name || files[i].name.trim().length === 0) continue;
                        fileList += `<span style="display:block;">${files[i].name}</span>`;
                    }
                    $form.parent().find('.upload__file__list span').show();
                    $form.parent().find('.upload__file__list span').html(fileList);
                    $form.find('.clear-upload').show();
                } else {
                    $form.parent().find('.upload__file__list span').hide();
                    $form.find('.clear-upload').show();
                }
            },
            allowedFileTypes = $input.attr('accept').split(',');

        if( !allowedFileTypes.length ) {
            modalGeneric('File Upload', 'Missing allowed attribute for file upload');
        }

        // letting the server side to know we are going to make an Ajax request
        // $form.append( '<input type="hidden" name="ajax" value="1" />' );

        // automatically submit the form on file select
        $input.on( 'change', function( e ){
            showFiles( e.target.files );
        });

        $form.find('.clear-upload').unbind('click').on('click', () => {
            $input.val('');
            $input.trigger('change');
        });

        // drag&drop files if the feature is available
        $form
            .addClass( 'has-advanced-upload' ) // letting the CSS part to know drag&drop is supported by the browser
            .on( 'drag dragstart dragend dragover dragenter dragleave drop', function( e ){
                // preventing the unwanted behaviours
                e.preventDefault();
                e.stopPropagation();
            })
            .on( 'dragover dragenter', function(){
                $form.addClass( 'is-dragover' );
            })
            .on( 'dragleave dragend drop', function(){
                $form.removeClass( 'is-dragover' );
            })
            .on( 'drop', function( e ){

                let errors = false,
                    disabledFileTypes = [];

                droppedFiles = e.originalEvent.dataTransfer.files;

                $.each(droppedFiles, (k,v) => {
                    const ext = ('.' + v.name.split('.').pop()).toLowerCase();

                    if( allowedFileTypes.indexOf(ext) < 0 ) {
                        errors = true;

                        if( disabledFileTypes.indexOf(ext) < 0 ) {
                            disabledFileTypes.push(ext);
                        }
                    }
                });

                if(!$input.attr('multiple') && droppedFiles.length > 1) {
                    modalGeneric('File Upload', 'You can only upload one file at a time.');
                    return;
                }

                if( errors ) {
                    modalGeneric('File Upload', 'The following file type(s) are not allowed: ' + disabledFileTypes.join(',').replace(/\./g,''));
                } else {
                    showFiles(droppedFiles);

                    $input.removeClass('required').prop('files', droppedFiles);
                }
            });

        // Firefox focus bug fix for file input
        $input.on( 'focus', function(){ $input.addClass( 'has-focus' ); }).on( 'blur', function(){ $input.removeClass( 'has-focus' ); });
    });
}